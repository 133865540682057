import React, { useState, useEffect, useRef } from "react";
import "./Offerings.scss";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Component/Navbar/navbar";
import Footer from "../../Component/Footer/Footer";
import axios from "axios";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import jsonData from "../../images";
import { BASE_URL } from "../../environments/config";
import { Helmet } from 'react-helmet';
import seoData from "../../Seo";
export default function Offerings() {

  const pageUrl = seoData["offerings"]?.PageURL;
  const metaTitle = seoData["offerings"]?.MetaTitle;
  const metaDescription = seoData["offerings"]?.MetaDescription;
  const metaKeywords = seoData["offerings"]?.Keywords;


  const [filter, setFilter] = useState("");
  const [active, setActive] = useState("All Pujas");
  const [pujaList, setPujaList] = useState([]);
  const [lifeEventList, setLifeEventList] = useState([]);
  const [occEventList, setOccEventList] = useState([]);
  const [allPujas, setAllPujas] = useState([]);
  const [isListVisible, setIsListVisible] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [navPuja, setNavPuja] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [showContactUs, setShowContactUs] = useState(false);
  const [pujasFilteredList, setPujasFilteredList] = useState([]);
  const dropdownRef = useRef(null);

  function changeTab(type) {
    setActive(type);
    localStorage.setItem("tab", type);
    setFilter("");
  }

  useEffect(() => {
    const fetchPujas = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/all-pujas`);

        const pujaListData = response.data.data[1].subCategories;
        setPujaList(pujaListData);

        const lifeEventData =
          response.data.data[0].subCategories[0].pujasubcategorymappings;
        setLifeEventList(lifeEventData);

        const tab = localStorage.getItem("tab") || "All Pujas";
        if (tab) setActive(tab);

        const selectedOcc = pujaListData.find((i) => i.name === active);
        if (selectedOcc) {
          setOccEventList(selectedOcc.pujasubcategorymappings);
        }
      } catch (err) {
        console.log("Error fetching pujas:", err);
      }
    };

    const fetchAllPujas = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/pujas`);
        const respDataMassage=response?.data?.data?.map((item)=>{return{...item,
          pujas:{pujamodels:item?.pujamodels}    //data masagging
          }})
        setAllPujas(respDataMassage);
      } catch (err) {
        console.log("Error fetching all-pujas:", err);
      }
    };

    if (active) {
      fetchPujas();
      fetchAllPujas();
    }
    setFilter("");
  }, [active]);

  useEffect(() => {
    if (active === "Life Event Based" && active !== "All Pujas") {
      setFilteredList(lifeEventList);
    } else if (active === "All Pujas" && active !== "Life Event Based") {
      setPujasFilteredList(allPujas);
    } else {
      setFilteredList(occEventList);
    }

    if (active !== "All Pujas") {
      const filteredPujaList = filteredList
        .map((puja) => {
          return {
            ...puja,
            name: puja.pujas.name,
            url_name: puja.pujas.url_name,
            id: puja.pujaId,
          };
        })
        ?.filter((puja) =>
          puja.pujas.name.toLowerCase().includes(filter.toLowerCase())
        );
      setSearchList(filteredPujaList);
    } else {
      const filteredPujaList = pujasFilteredList.filter((puja) =>
        puja.name.toLowerCase().includes(filter.toLowerCase())
      );
      setSearchList(filteredPujaList);
    }
  }, [
    active,
    lifeEventList,
    allPujas,
    occEventList,
    filteredList,
    filter,
    pujasFilteredList,
  ]);

  const handleInputClick = () => {
    setIsListVisible(true);
  };

  const handleInputChange = (e) => {
    setFilter(e.target.value);
    setNavPuja([]);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsListVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const redirection = (item, type) => {
    console.log(item);
    localStorage.setItem("pujaId", item?.id);

    if (item.length === 0) {
      return;
    }
    if (type === "all-pujas") {
      if (
        (item.length === 0 && filter && typeof filter === "string") ||
        item?.pujas?.pujamodels?.[0]?.isPopularModel === false
      ) {
        setShowContactUs(true);
        return;
      }
      if (item?.pujamodels[0]?.procedures?.length > 0) {
        localStorage.setItem("ToBooking", false);
        let navigateTo = "poojas/telugu/" + item?.url_name;
        window.open(navigateTo, "_self");
      } else {
        localStorage.setItem("ToBooking", true);
        let navigateTo = "booking/telugu/" + item?.url_name;
        window.open(navigateTo, "_self");
      }
    } else {
      if (
        (item.length === 0 && filter && typeof filter === "string") ||
        item?.pujamodels?.[0]?.isPopularModel === false
      ) {
        setShowContactUs(true);
        return;
      }
      if (item?.pujas?.pujamodels[0]?.procedures?.length > 0) {
        let navigateTo = "poojas/telugu/" + item?.url_name;
        window.open(navigateTo, "_self");
      } else {
        localStorage.setItem("ToBooking", true);
        let navigateTo = "booking/telugu/" + item?.url_name;
        window.open(navigateTo, "_self");
      }
    }
  };

  return (
    <>
    <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <Navbar />
      <div className="Offerings">
        <div className="container">
          <div className="search-section-container">
            <section className="search-section">
              <div className="All__pujas-container">
                <h2 className="search-section-heading">
                  Rituals brings goodness
                </h2>
                <div className="top-search-section">
                  <div className="search-container">
                    <div className="searchable" ref={dropdownRef}>
                      <input
                        type="text"
                        id="puja-name-field"
                        className="search-input pujadisableenable"
                        placeholder="Search pujas"
                        onkeyup="filterFunction(this,event)"
                        value={filter}
                        onChange={handleInputChange}
                        onClick={handleInputClick}
                      />
                      {isListVisible && (
                        <ul style={{ display: "block" }}>
                          {searchList.map((puja, index) => (
                            <li
                              key={index}
                              onClick={() => {
                                setFilter(puja.name);
                                setIsListVisible(false);
                                setNavPuja(puja);
                              }}
                            >
                              {puja.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <p className="choosecategory" style={{ marginTop: 20 }}>
                      Choose by categories
                    </p>
                    <div className="categories">
                      <div
                        className={
                          active === "All Pujas"
                            ? "category category-1 active"
                            : "category category-1"
                        }
                        onClick={() => changeTab("All Pujas")}
                      >
                        <img src="assets/images/all-pujas.png" alt="" />
                        <p className="category-type">All Pujas</p>
                      </div>
                      <div
                        className={
                          active === "Life Event Based"
                            ? "category category-2 active"
                            : "category category-2"
                        }
                        onClick={() => changeTab("Life Event Based")}
                      >
                        <img src="assets/images/life-based.png" alt="" />
                        <p className="category-type">Life Event Based</p>
                      </div>
                      <div
                        className={
                          active === "Remedial"
                            ? "category category-3 active"
                            : "category category-3"
                        }
                        onClick={() => changeTab("Remedial")}
                      >
                        <img
                          src="assets/images/remedial-based-icon.png"
                          alt=""
                        />
                        <p className="category-type">Remedial Based</p>
                      </div>
                      <div
                        className={
                          active === "Well Being"
                            ? "category category-4 active"
                            : "category category-4"
                        }
                        onClick={() => changeTab("Well Being")}
                      >
                        <img src="assets/images/well-being-icon.png" alt="" />
                        <p className="category-type">Wellbeing Puja</p>
                      </div>
                      <div
                        className={
                          active === "Season Special"
                            ? "cat5 category category-5 active"
                            : "cat5 category category-5"
                        }
                        onClick={() => changeTab("Season Special")}
                      >
                        <img
                          src="assets/images/season-basced-icon.png"
                          alt=""
                        />
                        <p className="category-type">Season Special</p>
                      </div>
                      <div
                        className={
                          active === "Wish Fulfilment"
                            ? "category category-6 active"
                            : "category category-6"
                        }
                        onClick={() => changeTab("Wish Fulfilment")}
                      >
                        <img src="assets/images/wish-full-icon.png" alt="" />
                        <p className="category-type">Wish fulfilment</p>
                      </div>
                      <div
                        className={
                          active === "Win over battles"
                            ? "category category-7 active"
                            : "category category-7"
                        }
                        onClick={() => changeTab("Win over battles")}
                      >
                        <img src="assets/images/win-over-icon.png" alt="" />
                        <p className="category-type">Win over battles</p>
                      </div>
                      <div
                        className={
                          active === "Homam"
                            ? "category category-8 active"
                            : "category category-8"
                        }
                        onClick={() => changeTab("Homam")}
                      >
                        <img src="assets/images/homan-icon.png" alt="" />
                        <p className="category-type">Homam</p>
                      </div>
                      <div
                        className={
                          active === "Vratam"
                            ? "category category-9 active"
                            : "category category-9"
                        }
                        onClick={() => changeTab("Vratam")}
                      >
                        <img src="assets/images/vratam-icon.png" alt="" />
                        <p className="category-type">Vratam</p>
                      </div>
                    </div>
                    <div className="bottom-search-div">
                      <button
                        className="search-button"
                        onClick={() => redirection(navPuja,"none")}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="not-found-container"
                  style={{ display: showContactUs ? "flex" : "none" }}
                >
                  <div class="not-found-text">
                    <p>Looking for something else?</p>
                    <p>Please reach out to us.</p>
                    <p></p>
                  </div>
                  <div class="not-found-contact">
                    <button class="contact-us-button contact-btn-offering">
                      <a href="/contact" previewlistener="true">
                        Contact Us
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="all-pujas-list-section">
            <div className="All__pujas-container">
              <h2 className="section-two-heading" style={{ display: "block" }}>
                Pujas
              </h2>
              <div className="pujs__contsiner pujs__contsiner-1 active">
                {active === "Life Event Based" &&
                  active !== "All Pujas" &&
                  lifeEventList.map((item) => {
                    return (
                      <div className="puja-card-col">
                        <div
                          className="each-puja-card"
                          onClick={() => {
                            localStorage.setItem("pujaId", item.pujas?.id);
                            let navigateTo =
                              "poojas/telugu/" + item?.pujas?.url_name;
                            window.open(navigateTo, "_self");
                          }}
                        >
                          <div className="each-puja-card-image">
                            {jsonData[item.pujas?.name] ? (
                              <img
                                src={jsonData[item.pujas?.name]}
                                className="all-pujas"
                                alt="image"
                              />
                            ) : (
                              <img
                                src="assets/images/default-no-puja-image.png"
                                className="all-pujas"
                                alt="image"
                              />
                            )}
                          </div>
                          <h5 className="each-puja-card-title">
                            {item.pujas.name}
                          </h5>
                          <p className="each-puja-card-description">
                            {item.pujas.description}
                          </p>
                          <img
                            src="assets/images/gold-line.png"
                            alt="image"
                            className="card-gold-line"
                          />
                          <div className="footer-each-card">
                            <div className="card-bottom">
                              <div className="card-puja-price-div"></div>
                              <div>
                                <button className="book-now-btn">
                                  Know More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {active === "All Pujas" &&
                  active !== "Life Event Based" &&
                  allPujas?.map((item) => {
                    return (
                      <div className="puja-card-col">
                        <div
                          className="each-puja-card"
                          onClick={() => redirection(item,"all-pujas")}
                        >
                          <div className="each-puja-card-image">
                            {jsonData[item?.name] ? (
                              <img
                                src={jsonData[item?.name]}
                                className="all-pujas"
                                alt="image"
                              />
                            ) : (
                              <img
                                src="assets/images/default-no-puja-image.png"
                                className="all-pujas"
                                alt="image"
                              />
                            )}
                          </div>
                          <h5 className="each-puja-card-title">{item?.name}</h5>
                          <p className="each-puja-card-description">
                            {item?.description}
                          </p>
                          <img
                            src="assets/images/gold-line.png"
                            alt="image"
                            className="card-gold-line"
                          />
                          <div className="footer-each-card">
                            <div className="card-bottom">
                              <div className="card-puja-price-div"></div>
                              <div>
                                <button className="book-now-btn">
                                  Know More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {active !== "Life Event Based" &&
                  active !== "All Pujas" &&
                  occEventList?.map((item) => {
                    return (
                      <div className="puja-card-col">
                        <div
                          className="each-puja-card"
                          onClick={() => {
                            localStorage.setItem("pujaId", item.pujas?.id);
                            let navigateTo =
                              "poojas/telugu/" + item?.pujas?.url_name;
                            window.open(navigateTo, "_self");
                          }}
                        >
                          <div className="each-puja-card-image">
                            {jsonData[item.pujas?.name] ? (
                              <img
                                src={jsonData[item.pujas?.name]}
                                className="all-pujas"
                                alt="image"
                              />
                            ) : (
                              <img
                                src="assets/images/default-no-puja-image.png"
                                className="all-pujas"
                                alt="image"
                              />
                            )}
                          </div>
                          <h5 className="each-puja-card-title">
                            {item.pujas.name}
                          </h5>
                          <p className="each-puja-card-description">
                            {item.pujas.description}
                          </p>
                          <img
                            src="assets/images/gold-line.png"
                            alt="image"
                            className="card-gold-line"
                          />
                          <div className="footer-each-card">
                            <div className="card-bottom">
                              <div className="card-puja-price-div"></div>
                              <div>
                                <button className="book-now-btn">
                                  Know More
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Whatsapp />
    </>
  );
}
